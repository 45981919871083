<template>
    <client-page class="wrap--light">
        <page-section class="page-section--first page-section--last">
            <h2 class="join-title">회원가입</h2>

            <div class="join-container">
                <v-row justify="center" class="row--xl">
                    <v-col cols="12" md="6">
                        <v-card outlined class="rounded-lg pa-20 px-md-84 py-md-60">
                            <terms-of-agreements ref="terms" />
                            <v-card-actions class="pa-0 pt-12 pt-md-16">
                                <v-btn block color="#FF3A4A" dark x-large @click="submit">본인인증 하기</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
            </div>
        </page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import PageSection from "@/components/client/sub/page-section.vue";
import TermsOfAgreements from "@/components/client/join/terms-of-agreements.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        TermsOfAgreements,
    },
    methods: {
        submit() {
            try {
                if (this.$refs.terms.check()) {
                    this.$store.dispatch("agreements", this.$refs.terms.selected);

                    this.$router.push({
                        path: "/join/form",
                        query: this.$route.query,
                    });
                }
            } catch (error) {
                alert(error.message.toString());
            }
        },
    },
};
</script>
