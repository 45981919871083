<template>
    <client-page class="wrap--light">
		<!-- 메인 -->
		<page-section class="page-section--first page-section--last">
            <div class="join-container">
                <template v-if="hasAdditionalForm">
                    <v-layout column align-center class="text-center"> 
                        <i class="icon icon-check-primary mb-20 mb-md-40" />
                        <h4 class="tit mb-16 mb-md-20">회원가입이 완료 되었습니다.</h4>
                        <p class="page-text page-text--lg grey--text">아래의 추가 입력 정보를 입력해 주세요.<br /> 지금 입력하지 않아도 다음에 입력하실 수 있습니다.</p>
                        <join-additional-form class="mt-30 mt-md-60" v-bind="{ type, kind }" />
                    </v-layout>
                </template>
                <template v-else>
                    <v-layout column align-center class="text-center"> 
                        <i class="icon icon-check-primary mb-20 mb-md-40" />
                        <h4 class="tit mb-16 mb-md-20">회원가입이 완료 되었습니다.</h4>
                        <p class="page-text page-text--lg grey--text">가입해 주셔서 감사합니다.</p>
                        <div class="v-btn--group">
                            <v-btn to="/" large rounded dark color="secondary">
                                <span>확인</span>
                            </v-btn>
                        </div>
                    </v-layout>
                </template>
                <!-- <i class="icon icon-check-primary" />
                <template v-if="hasAdditionalForm">
                    <div class="join-complate">
                        <i class="join-complate__icon"></i>
                        <h3 class="join-complate__title">회원가입이 완료 되었습니다.</h3>
                        <p class="join-complate__text mt-40" style="line-height: 1.5">
                            아래의 추가 입력 정보를 입력해 주세요.<br />
                            지금 입력하지 않아도 다음에 입력하실 수 있습니다.
                        </p>
                    </div>
                    <join-additional-form class="mt-100" v-bind="{ type, kind }" />
                </template>
                <template v-else>
                    <div class="join-complate">
                        <i class="join-complate__icon"></i>
                        <h3 class="join-complate__title">회원가입이 완료 되었습니다.</h3>
                        <p class="join-complate__text">가입해 주셔서 감사합니다.</p>
                    </div>
                </template> -->
            </div>
		</page-section>
    </client-page>
</template>

<script>
import { INDIVIDUAL_KINDS } from "@/assets/variables";

import ClientPage from "@/pages/client/templates/ClientPage.vue";
import PageSection from "@/components/client/sub/page-section.vue";
import JoinAdditionalForm from "@/components/client/join/join-additional-form.vue";

export default {
    components: {
		ClientPage,
		PageSection,
        JoinAdditionalForm,
    },
    computed: {
        type() {
            return this.$route.query.type;
        },
        kind() {
            return this.$route.query.kind;
        },
        hasAdditionalForm() {
            return [INDIVIDUAL_KINDS.SUPPORTER.value].includes(this.kind);
        },
    },
    created() {},
    mounted() {
        this.init();
    },
    methods: {
        init: function () {},
    },
};
</script>
