<template>
    <client-page class="wrap--light">
        <page-section class="page-section--first page-section--last">
            <h2 class="join-title">회원가입</h2>
            <div class="join-container">
                <div class="join-gate">
                    <!-- 개인 -->
                    <div class="join-gate-box rounded join-gate-box--personal">
                        <div class="join-gate-box__inner">
                            <i class="icon icon-general"></i>
                            <h2>일반회원 가입</h2>
                            <v-btn @click="submit(USER_TYPES.INDIVIDUAL)" rounded color="secondary" class="mt-30">가입하기</v-btn>
                        </div>
                    </div>
                    <!-- 업체 -->
                    <div class="join-gate-box rounded join-gate-box--business">
                        <div class="join-gate-box__inner">
                            <i class="icon icon-business"></i>
                            <h2>기업회원 가입</h2>
                            <v-btn @click="submit(USER_TYPES.ORGANIZATION)" rounded color="primary" class="mt-30">가입하기</v-btn>
                        </div>
                    </div>
                </div>
            </div>
        </page-section>
    </client-page>
</template>

<script>
import { USER_TYPES } from "@/assets/variables";

import ClientPage from "@/pages/client/templates/ClientPage.vue";
import PageSection from "@/components/client/sub/page-section.vue";

export default {
    components: {
		ClientPage,
        PageSection,
    },
    data() {
        return { USER_TYPES };
    },
    methods: {
        submit(code) {
            this.$router.push(`/join?code=${code}`);
        },
    },
};
</script>

<style scoped>
table, table tr th, table tr td{
    border: 1px solid #ddd;
}
table tr th, table tr td{
    border: 1px solid #ddd;
}
p{
    line-height: 1.6;
}
</style>
