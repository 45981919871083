<template>
    <client-page class="wrap--light">
		<!-- 메인 -->
		<page-section class="page-section--first page-section--last">
            <h2 class="join-title">회원가입</h2>

            <div class="join-container">
                <!-- 가입 단계 표시 -->
                <nav class="join-step">
                    <ul class="join-step-lists">
                        <li class="list">
                            <span class="badge">1</span>
                            <span class="text">약관동의</span>
                        </li>
                        <li class="list list--on">
                            <span class="badge">2</span>
                            <span class="text">정보입력</span>
                        </li>
                        <li class="list">
                            <span class="badge">3</span>
                            <span class="text">가입완료</span>
                        </li>
                    </ul>
                </nav>

                <join-form :code="code" :skin="code"></join-form>
            </div>
		</page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import PageSection from "@/components/client/sub/page-section.vue";
import JoinForm from "@/components/client/join/join-form.vue";

export default {
    components: {
		ClientPage,
		PageSection,
        JoinForm,
    },
    computed: {
        code() {
            return this.$route.query?.code;
        },
    },
};
</script>
