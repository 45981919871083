var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(_vm.SkinComponent, {
    tag: "component",
    attrs: {
      "areas": _vm.areas_structured
    },
    on: {
      "submit": _vm.submit
    },
    model: {
      value: _vm.user,
      callback: function ($$v) {
        _vm.user = $$v;
      },
      expression: "user"
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }