var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "wrap--light"
  }, [_c('page-section', {
    staticClass: "page-section--first page-section--last"
  }, [_c('h2', {
    staticClass: "join-title"
  }, [_vm._v("회원가입")]), _c('div', {
    staticClass: "join-container"
  }, [_c('div', {
    staticClass: "join-gate"
  }, [_c('div', {
    staticClass: "join-gate-box rounded join-gate-box--personal"
  }, [_c('div', {
    staticClass: "join-gate-box__inner"
  }, [_c('i', {
    staticClass: "icon icon-general"
  }), _c('h2', [_vm._v("일반회원 가입")]), _c('v-btn', {
    staticClass: "mt-30",
    attrs: {
      "rounded": "",
      "color": "secondary"
    },
    on: {
      "click": function ($event) {
        return _vm.submit(_vm.USER_TYPES.INDIVIDUAL);
      }
    }
  }, [_vm._v("가입하기")])], 1)]), _c('div', {
    staticClass: "join-gate-box rounded join-gate-box--business"
  }, [_c('div', {
    staticClass: "join-gate-box__inner"
  }, [_c('i', {
    staticClass: "icon icon-business"
  }), _c('h2', [_vm._v("기업회원 가입")]), _c('v-btn', {
    staticClass: "mt-30",
    attrs: {
      "rounded": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.submit(_vm.USER_TYPES.ORGANIZATION);
      }
    }
  }, [_vm._v("가입하기")])], 1)])])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }