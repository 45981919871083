var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._l(_vm.termsList, function (terms) {
    return _c('div', {
      key: terms._id,
      staticClass: "join-row"
    }, [_c('h2', {
      staticClass: "tit tit--sm"
    }, [_vm._v(_vm._s(terms.subject))]), _c('div', {
      staticClass: "box-style"
    }, [terms.content ? _c('div', {
      staticClass: "join-box"
    }, [_c('p', {
      staticClass: "textarea",
      attrs: {
        "name": "terms",
        "readonly": ""
      },
      domProps: {
        "innerHTML": _vm._s(terms.content)
      }
    })]) : _vm._e()]), _c('div', {
      staticClass: "mt-15px text-right"
    }, [_c('v-checkbox', {
      attrs: {
        "value": terms._id,
        "label": "동의합니다.",
        "color": "secondary",
        "hide-details": ""
      },
      model: {
        value: _vm.selected,
        callback: function ($$v) {
          _vm.selected = $$v;
        },
        expression: "selected"
      }
    })], 1)]);
  }), _c('div', {
    staticClass: "join-row"
  }, [_c('div', {
    staticClass: "join-caption join-caption--block"
  }, [_c('h3', {
    staticClass: "tit tit--sm"
  }, [_vm._v("전체선택")]), _c('v-checkbox', {
    attrs: {
      "checked": _vm.termsList.length == _vm.selected.length,
      "label": "모두 동의합니다.",
      "color": "secondary",
      "hide-details": ""
    },
    on: {
      "click": function ($event) {
        _vm.selected = _vm.termsList.length == _vm.selected.length ? [] : _vm.termsList.map(function (terms) {
          return terms._id;
        });
      }
    }
  })], 1)])], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }