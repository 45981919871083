<template>
    <div>
        <div v-for="terms in termsList" :key="terms._id" class="join-row">
            <h2 class="tit tit--sm">{{ terms.subject }}</h2>
            <div class="box-style">
                <div v-if="terms.content" class="join-box">
                    <p class="textarea" name="terms" readonly v-html="terms.content">
                    </p>
                </div>
            </div>
            <div class="mt-15px text-right">
                <v-checkbox v-model="selected" :value="terms._id" label="동의합니다." color="secondary" hide-details /> 
                <!-- <label class="check">
                    <input v-model="selected" name="terms" class="input" type="checkbox" :value="terms._id">
                    <i class="icon"></i>
                    <span class="text">동의합니다.</span>
                </label> -->
            </div>
        </div>

        <!-- 전체동의 -->
        <div class="join-row">
            <div class="join-caption join-caption--block">
                <h3 class="tit tit--sm">전체선택</h3>
                <v-checkbox :checked="termsList.length == selected.length" label="모두 동의합니다." color="secondary" hide-details @click="selected = (termsList.length == selected.length) ? [] : termsList.map((terms) => terms._id);" /> 
                <!-- <label class="check"><input type="checkbox" class="input" :checked="termsList.length == selected.length" @click="selected = (termsList.length == selected.length) ? [] : termsList.map((terms) => terms._id);">
                    <i class="icon"></i>
                    <span class="text">모두 동의합니다.</span>
                </label> -->
            </div>
        </div>
    </div>
</template>

<script>
import api from "@/api";
export default {
    props: ["code"],
    data(){
        return {
            selected: [],
            termsList: [],

            filter: {
                code: this.$props.code || undefined
            }
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            try{
                var { termsList } = await api.v1.terms.gets({ params: this.filter });
                this.termsList = termsList;
            }
            catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        check(){
            try{
                this.termsList.forEach((terms) => {
                    if(terms.necessary && this.selected.indexOf(terms._id) < 0){
                        throw new Error(`[${terms.subject}]에 동의해 주세요`);
                    }
                })

                return true;
            }
            catch(error){
                alert(error.message);
                return false;
            }
        },
    }
}
</script>
