<template>
    <component :is="SkinComponent" v-model="user" :areas="areas_structured" @submit="submit"></component>
</template>

<script>
import api from "@/api";
import CryptoAES from "@/plugins/crypto-aes";
import { USER_TYPES } from "@/assets/variables";
import { mapActions } from "vuex";

export default {
    props: ["skin", "code"],
    data() {
        return {
            user: {
                _terms: this.$store.state.agreements || [],

                type: this.$props.code || USER_TYPES.INDIVIDUAL,

                username: null,
                password: null,
                passwordConfirm: null,

                name: null,

                phone: null,
                phone1: "010",
                phone2: null,
                phone3: null,

                email: null,
                email1: null,
                email2: null,
                email3: null,

                postcode: null,
                address1: null,
                address2: null,

                _areas: [],
                areas_manual: {},

                meta: {},
            },
            areas: [],
        };
    },
    computed: {
        SkinComponent() {
            return () => import(`./skin/${this.$props.skin}/form.vue`);
        },
        areas_structured() {
            const parents = (this.areas || []).filter(({ _parent }) => !_parent).sort((a, b) => a.order - b.order);
            const areas = parents.map((parent) => ({
                ...parent,
                children: (this.areas || []).filter(({ _parent }) => _parent == parent._id).sort((a, b) => a.order - b.order),
            }));
            return areas;
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        ...mapActions(["login"]),

        async init() {
            // var { areas } = await api.v1.areas.gets();
            // this.areas = areas;
        },

        async submit() {
            try {
                ////////////////////////////////////////////////////////////////
                // 폼데이터 정돈
                ////////////////////////////////////////////////////////////////
                let { ...form } = this.user;

                form.password = CryptoAES.encrypt(this.user.password);
                delete form.passwordConfirm;

                ////////////////////////////////////////////////////////////////
                // 파일변수 선언
                ////////////////////////////////////////////////////////////////
                let { _policies = [], policies = [] } = form.blacksmith.insurance;
                delete form.blacksmith.insurance.policies;

                let { _equipmentUseConsent, equipmentUseConsent } = form.blacksmith;
                delete form.blacksmith.equipmentUseConsent;

                let { _businessRegistration, businessRegistration } = form;
                delete form.businessRegistration;

                ////////////////////////////////////////////////////////////////
                // 회원 생성/로그인
                ////////////////////////////////////////////////////////////////
                const { user } = await api.v1.users.post(form);

                let { username, password } = this.user || {};
                await this.login({ username, password });

                ////////////////////////////////////////////////////////////////
                // 파일 처리
                ////////////////////////////////////////////////////////////////

                ////////////////////
                // 보험증서
                ////////////////////
                if (policies.length) {
                    // 추가파일 생성처리
                    policies = await Promise.all(policies.map(async (file, index) => (file instanceof File ? (await api.v1.common.files.post({ path: "insurance-policies", index }, file))?.file : file)));

                    // 제거파일 삭제처리
                    await Promise.all(_policies.filter((_policy) => !policies.some((item) => item._id == _policy)).map(async (_id) => await api.v1.common.files.delete({ _id })));
                }
                // 식별코드 업데이트
                _policies = policies.map(({ _id }) => _id);
                ////////////////////

                ////////////////////
                // 장비이용 동의서
                ////////////////////
                if (equipmentUseConsent) {
                    // 추가파일 생성처리
                    equipmentUseConsent = equipmentUseConsent instanceof File ? (await api.v1.common.files.post({ path: "equipment-use-consent" }, equipmentUseConsent))?.file : equipmentUseConsent;

                    // 제거파일 삭제처리
                    if (!!_equipmentUseConsent && _equipmentUseConsent != equipmentUseConsent?._id) await api.v1.common.files.delete({ _id: _equipmentUseConsent });
                }
                // 식별코드 업데이트
                _equipmentUseConsent = equipmentUseConsent?._id;
                ////////////////////

                ////////////////////
                // 사업자등록증
                ////////////////////
                if (businessRegistration) {
                    // 추가파일 생성처리
                    businessRegistration = businessRegistration instanceof File ? (await api.v1.common.files.post({ path: "business-registration" }, businessRegistration))?.file : businessRegistration;

                    // 제거파일 삭제처리
                    if (!!_businessRegistration && _businessRegistration != businessRegistration?._id) await api.v1.common.files.delete({ _id: _businessRegistration });
                }
                // 식별코드 업데이트
                _businessRegistration = businessRegistration?._id;
                ////////////////////

                ////////////////////////////////////////////////////////////////
                // 회원 업데이트
                ////////////////////////////////////////////////////////////////
                await api.v1.me.put({
                    _businessRegistration,

                    blacksmith: {
                        ...(user?.blacksmith || {}),

                        _equipmentUseConsent,
                        insurance: {
                            ...(user?.blacksmith?.insurance || {}),
                            _policies,
                        },
                    },
                });

                let { type, kind } = this.user || {};
                let query = { type, kind };
                if (!type) delete query.type;
                if (!kind) delete query.kind;

                this.$router.push({ path: "/join/complete", query });
            } catch (error) {
                this.$handleError(error);
            }
        },
    },
};
</script>
