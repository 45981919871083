var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "wrap--light"
  }, [_c('page-section', {
    staticClass: "page-section--first page-section--last"
  }, [_c('div', {
    staticClass: "join-container"
  }, [_vm.hasAdditionalForm ? [_c('v-layout', {
    staticClass: "text-center",
    attrs: {
      "column": "",
      "align-center": ""
    }
  }, [_c('i', {
    staticClass: "icon icon-check-primary mb-20 mb-md-40"
  }), _c('h4', {
    staticClass: "tit mb-16 mb-md-20"
  }, [_vm._v("회원가입이 완료 되었습니다.")]), _c('p', {
    staticClass: "page-text page-text--lg grey--text"
  }, [_vm._v("아래의 추가 입력 정보를 입력해 주세요."), _c('br'), _vm._v(" 지금 입력하지 않아도 다음에 입력하실 수 있습니다.")]), _c('join-additional-form', _vm._b({
    staticClass: "mt-30 mt-md-60"
  }, 'join-additional-form', {
    type: _vm.type,
    kind: _vm.kind
  }, false))], 1)] : [_c('v-layout', {
    staticClass: "text-center",
    attrs: {
      "column": "",
      "align-center": ""
    }
  }, [_c('i', {
    staticClass: "icon icon-check-primary mb-20 mb-md-40"
  }), _c('h4', {
    staticClass: "tit mb-16 mb-md-20"
  }, [_vm._v("회원가입이 완료 되었습니다.")]), _c('p', {
    staticClass: "page-text page-text--lg grey--text"
  }, [_vm._v("가입해 주셔서 감사합니다.")]), _c('div', {
    staticClass: "v-btn--group"
  }, [_c('v-btn', {
    attrs: {
      "to": "/",
      "large": "",
      "rounded": "",
      "dark": "",
      "color": "secondary"
    }
  }, [_c('span', [_vm._v("확인")])])], 1)])]], 2)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }